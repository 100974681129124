import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// core components
import styles from "assets/jss/material-dashboard-react/components/snackbarContentStyle.js";

const useStyles = makeStyles(styles);

export default function SnackbarContent(props) {
  const classes = useStyles();

  const { message, title, color, close, icon, rtlActive, buttons } = props;
  var action = [];

  let messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined,
  });
  const messageClassesRtl = classNames({
    [classes.iconMessageRtl]: icon !== undefined,
  });
  if(rtlActive)
    messageClasses = messageClassesRtl

  const actionButton=(act) => {
    props.onClose();
    if(isFunction(act))
      act();
  }

  function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  if (close === true) {
    action = [
      <IconButton
        className={rtlActive? classes.iconButtonRtl : classes.iconButton}
        color="inherit"
        onClick={props.onClose}
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }

  let rtlClass = props.rtlActive ? ' ' + classes.actionRTL : ''
  
  return (
    <Snack
      message={
        <div>
          <div>
            {icon !== undefined ? <props.icon className={rtlActive? classes.iconRtl : classes.icon} /> : null}
            {title !== undefined ? <p className={classes.title + ' ' + messageClasses}>{title}</p> : null}
            <span className={classes.messageContent + ' ' + messageClasses}>{message}</span>
          </div>

          { Array.isArray(buttons) &&
          <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{paddingTop: `1em`}}>
            {buttons.map((btn, i) =>
              <Button variant="outlined" size="small" className={classes[color]} style={{marginLeft: `3px`}} 
                      onClick={()=>actionButton(btn.action)}>{btn.title}</Button>
            )}
          </Grid>
          }
        </div>
      }
      classes={{
        root: classes.root + " " + classes[color] + ` ${props.className}` + rtlClass,
        message: classes.message,
        action: classNames({ [classes.actionRTL]: rtlActive }),
      }}
      action={action}
    />
  );
}

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  close: PropTypes.bool,
  icon: PropTypes.object,
  rtlActive: PropTypes.bool,
  buttons: PropTypes.array,
  onClose: PropTypes.func,
};
