
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

export default function App(){
    const [plugins, setPlugins] = React.useState(null);
  
    React.useEffect(() => {
      fetch("/getPlugins")
        .then((res) => res.json())
        .then((data) => setPlugins(data.plugins));
    }, []);
  
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/admin" component={() => <Admin plugins={plugins} />} />
                <Route path="/rtl" component={RTL} />
                <Redirect from="/" to="/admin/dashboard" />
            </Switch>
        </BrowserRouter>
    )
}