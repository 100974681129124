import React from 'react';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import PropTypes from 'prop-types';

import 'assets/css/material-dashboard-react/components/MessageProvider.css';

const Notification = props => {    
    React.useEffect(()=>{    
        let id= 0;
        if(props.autoDismiss === true){
            id = setInterval(props.onClose, props.autoDismissTimeOut);

            return()=>{
                clearInterval(id)
            }
        }
    });

    const onClose = ()=>{
        props.onClose();
    }

    return(
        <SnackbarContent index={props.index}
            message={props.message}
            close={props.displayCloseButton || !props.autoDismiss}
            color={props.type}
            title={props.title}
            icon={props.icon}
            onClose={onClose}
            buttons={props.buttons}
            className={`notification toast ${props.position}`}
            rtlActive={props.rtlActive}
        />
    )
}

Notification.propTypes = {
    title: PropTypes.string,
    message: PropTypes.node.isRequired,
    type: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
    displayCloseButton: PropTypes.bool,
    icon: PropTypes.object,
    rtlActive: PropTypes.bool,
    autoDismiss: PropTypes.bool,
    autoDismissTimeOut: PropTypes.number,
    onClose: PropTypes.func,
    position:  PropTypes.oneOf(["bottom-right", "bottom-left", "top-right", "top-left"]),
    buttons: PropTypes.arrayOf(
      PropTypes.exact({
        title: PropTypes.string,
        action: PropTypes.func
      }),
    ),
    displayMethod: PropTypes.oneOf(["modal", "notification"]),
}

Notification.defaultProps = {
    displayCloseButton: true,
    type: 'info',
    rtlActive: false,
    autoDismiss: true,
    autoDismissTimeOut: 3000,
    position: 'bottom-right',
    displayMethod: 'notification',
}

export default Notification;