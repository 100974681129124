import React, {FunctionComponent, MouseEvent, useState} from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Search from '@material-ui/icons/Search';
import { Icon } from '@iconify/react';
import SelectionEllipse from '@iconify-icons/mdi/selection-ellipse';
import VectorPolygon from '@iconify-icons/mdi/vector-polygon';
import VectorPolyline from '@iconify-icons/mdi/vector-polyline';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import CheckboxMarkedOutline from '@iconify-icons/mdi/checkbox-marked-outline';
import CameraOutline from '@iconify-icons/mdi/camera-outline';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import CSS from 'csstype';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: primaryColor[0],
      main: primaryColor[1],
      dark: primaryColor[2],
      contrastText: primaryColor[3],
    },
  },
});

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: primaryColor[2],
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  root: {
  },
  popper: {
    zIndex: 1000,
    width: 350,
  },
  extraSearchIcon:{
    position: 'absolute',
    right: '-7px',
    bottom: '-7px',
    width: '20px',
  },
  geoSearchIcon:{
    position: 'relative', 
    width: '24px', 
    height: '24px',
  },
  popperContent: {
    padding: theme.spacing(2),
  },
  popperFooter: {
    padding: theme.spacing(2, 4, 2, 2),
  },
  typography: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  primaryColor: {
    color: primaryColor[0],
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  searchSettingContent: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  searchSettingSubtitle: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  formControl: {
    minWidth: '3em',
  },
}));

const MapsToolbar: FunctionComponent = () => {
    const classes = useStyles();
    const [anchorMenu, setAnchorMenu] = useState<any>(null);
    const [anchorPopper, setAnchorPopper] = useState<any>(null);
    const [openPopper, setOpenPopper] = useState(false);
    const [activePopper, setActivePopper] = useState<string>('none' || 'circle' || 'polygon' || 'route');

    const isMenuOpen = Boolean(anchorMenu);

    const handleSelectionMenuOpen = (event: MouseEvent) => {
      handlePopperClose();
      setAnchorMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorMenu(null);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Select all objects</MenuItem>
        <MenuItem onClick={handleMenuClose}>Deselect all objects</MenuItem>
        <MenuItem onClick={handleMenuClose}>Invert selection</MenuItem>
      </Menu>
    );

    const extraSearchIcon = (color: CSS.Property.Color) => {
      const style: CSS.Properties = {color: color}
      return (<Search style={style} className={classes.extraSearchIcon} />);
    };

    const circleSearchIcon = (color: string = "inherit") => {
      const style: CSS.Properties = {color: color}
      return(
        <div className={classes.geoSearchIcon}>
          <Icon style={style} icon={SelectionEllipse} width="24" height="24" />
          {extraSearchIcon(color)}
        </div>
      );
    }

    const polygonSearchIcon = (color: string = "inherit") => {
      const style: CSS.Properties = {color: color}
      return(
        <div className={classes.geoSearchIcon}>
          <Icon style={style} icon={VectorPolygon} width="24" height="24" />
          {extraSearchIcon(color)}
        </div>
      );
    }

    const polylineSearchIcon = (color: string = "inherit") => {
      const style: CSS.Properties = {color: color}
      return(
        <div className={classes.geoSearchIcon}>
          <Icon style={style} icon={VectorPolyline} width="24" height="24" />
          {extraSearchIcon(color)}
        </div>
      );
    }

    const handlePopperOpen = (activedPopper: string) => (event: MouseEvent) => {
      handleMenuClose()
      setAnchorPopper(event.currentTarget);
      setOpenPopper((prev) => activePopper !== activedPopper || !prev);
      setActivePopper(activedPopper);
    };

    const handlePopperClose = () => {
      setOpenPopper(false)
    }

    const popperEl = (
      <Popper open={openPopper} anchorEl={anchorPopper} placement="bottom-start" className={classes.popper} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.popperContent}>
              <IconButton
                className={classes.closeButton}
                color="inherit"
                onClick={handlePopperClose}>
                <Close className="close-Icon" />
              </IconButton>

              {activePopper == 'circle'? circleContent : null}
              {activePopper == 'polygon'? polygonContent : null}
              {activePopper == 'route'? routeContent : null}
            </Paper>
          </Fade>
        )}
      </Popper>
    )

    const circleContent = (
      <Grid container spacing={4} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center">
            {circleSearchIcon(primaryColor[0])} <Typography variant="h4" className={classes.typography+ ' ' + classes.primaryColor}>Circle Search</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.primaryColor}>Search Setting</Typography>
          <Grid item className={classes.searchSettingContent}>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Center: </Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>[0.0000, 0.0000]</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Radius: </Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>0.00</Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>km</Typography>
            </Box>
          </Grid>
        </Grid>
        
        <Grid container xs={12} alignItems="center" className={classes.popperFooter} >
          <Grid item xs={9}>
            <Typography color="textPrimary" variant="caption">
              Drag to move or resize circle.
            </Typography>
          </Grid>        
          <Grid item xs={3}>
            <Button color="primary">Search</Button>
          </Grid>
        </Grid>
      </Grid>
    )

    const polygonContent = (
      <Grid container spacing={4} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center">
            {polygonSearchIcon(primaryColor[0])} <Typography variant="h4" className={classes.typography+ ' ' + classes.primaryColor}>Polygon Search</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.primaryColor}>Search Setting</Typography>
          <Grid item className={classes.searchSettingContent}>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Points: </Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>0</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Perimeter: </Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>0.00</Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>km</Typography>
            </Box>
          </Grid>
        </Grid>
        
        <Grid container xs={12} alignItems="center" className={classes.popperFooter} >
          <Grid item xs={9}>
            <Typography color="textPrimary" variant="caption">
              Click to draw polygon.
            </Typography>
          </Grid>        
          <Grid item xs={3}>
            <Button color="primary">Search</Button>
          </Grid>
        </Grid>
      </Grid>
    );

    const routeContent = (
      <Grid container spacing={4} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center">
            {polylineSearchIcon(primaryColor[0])} <Typography variant="h4" className={classes.typography+ ' ' + classes.primaryColor}>Route Search</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.primaryColor}>Search Setting</Typography>
          <Grid item className={classes.searchSettingContent}>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Points: </Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>0</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Length: </Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>0.00</Typography>
              <Typography color="textPrimary" className={classes.searchSettingSubtitle}>km</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary" className={classes.searchSettingSubtitle}>Buffer: </Typography>
              <ThemeProvider theme={theme}>
                <FormControl className={classes.formControl + ' ' + classes.searchSettingSubtitle}>
                  <TextField id="standard-number" type="number" InputLabelProps={{ shrink: true, }} defaultValue="300" style={{color: 'red' }} />
                </FormControl>
                <FormControl className={classes.formControl + ' ' + classes.searchSettingSubtitle}>
                  <NativeSelect name="unit" inputProps={{ 'aria-label': 'unit' }} >
                    <option value="m">m</option>
                    <option value="km">km</option>
                  </NativeSelect>
                </FormControl>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
        
        <Grid container xs={12} alignItems="center" className={classes.popperFooter} >
          <Grid item xs={9}>
            <Typography color="textPrimary" variant="caption">
              Click to draw route.
            </Typography>
          </Grid>        
          <Grid item xs={3}>
            <Button color="primary">Search</Button>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
        <div className={classes.grow}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar>
              <div className={classes.sectionDesktop}>
                <IconButton aria-label="show 4 new mails" color="inherit" onClick={handlePopperOpen('circle')}>
                  {circleSearchIcon()}
                </IconButton>
                <IconButton aria-label="show 4 new mails" color="inherit" onClick={handlePopperOpen('polygon')}>
                  {polygonSearchIcon()}
                </IconButton>
                <IconButton aria-label="show 4 new mails" color="inherit" onClick={handlePopperOpen('route')}>
                  {polylineSearchIcon()}
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <DeleteForeverOutlined />
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="inherit" onClick={handleSelectionMenuOpen}>
                  <Icon icon={CheckboxMarkedOutline} aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" 
                        />
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <Icon icon={CameraOutline} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        {renderMenu}
        {popperEl}
        </div>
    );
}

export default MapsToolbar;