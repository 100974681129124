/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MessageProvider from 'components/MessageProvider/MessageProvider.js';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);


export default function Notifications() {
  const classes = useStyles();
  const [messageList, setMessageList] = React.useState([]);

  const [state, setState] = React.useState({
    displayMethod: 'notification',
    position: 'bottom-right',
    type: 'info',
    autoDismiss: true,
  });


  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChecked = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  React.useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });

  const addNotify = () => {
    let newelement = {
      id: '',
      type: state.type,
      title:'Title',
      message: 'This is a test message for display a message.',
      displayCloseButton: true,
      autoDismiss: state.autoDismiss,
      autoDismissTimeOut: 3000,
      rtlActive: false,
      buttons: [
        {title: 'No', action: noAction},
        {title: 'Yes', action: yesAction},
      ],
      displayMethod: state.displayMethod,
    }

    setMessageList([...messageList, newelement])
  }

  const yesAction = () => {
    console.log('yes')
  }

  const noAction = () => {
    console.log('No')
  }

  const closeNotify = () => {
    console.log('closed')
  }
  
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Notifications</h4>
        <p className={classes.cardCategoryWhite}>
          Handcrafted by our friends from{" "}
          <a
            target="_blank"
            href="https://material-ui-next.com/?ref=creativetime"
          >
            Material UI
          </a>{" "}
          and styled by{" "}
          <a
            target="_blank"
            href="https://www.creative-tim.com/?ref=mdr-notifications-page"
          >
            Creative Tim
          </a>
          . Please checkout the{" "}
          <a href="#pablo" target="_blank">
            full documentation
          </a>
          .
        </p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h5>Notifications Style</h5>
            <br />
            <SnackbarContent message={"This is a plain notification"} />
            <SnackbarContent
              message={"This is a notification with close button."}
              close
            />
            <SnackbarContent
              message={"This is a notification with close button and icon."}
              close
              icon={AddAlert}
            />
            <SnackbarContent
              message={
                "This is a notification with close button and icon and have many lines. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style."
              }
              close
              icon={AddAlert}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h5>Notifications States</h5>
            <br />
            <SnackbarContent
              message={
                'INFO - This is a regular notification made with color="info"'
              }
              close
              color="info"
            />
            <SnackbarContent
              message={
                'SUCCESS - This is a regular notification made with color="success"'
              }
              close
              color="success"
            />
            <SnackbarContent
              message={
                'WARNING - This is a regular notification made with color="warning"'
              }
              close
              color="warning"
            />
            <SnackbarContent
              message={
                'DANGER - This is a regular notification made with color="danger"'
              }
              close
              color="danger"
            />
            <SnackbarContent
              message={
                'PRIMARY - This is a regular notification made with color="primary"'
              }
              close
              color="primary"
            />
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
            <h5>
              Messages Provider
            </h5>
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                className={classes.selectEmpty}
                value={state.displayMethod}
                name="displayMethod"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'displayMethod' }}
              >
                <option value="" disabled>
                Display Method
                </option>
                <option value="modal">Modal</option>
                <option value="notification">Notification</option>
              </NativeSelect>
              <FormHelperText>Display Method</FormHelperText>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                className={classes.selectEmpty}
                value={state.position}
                name="position"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'position' }}
              >
                <option value="" disabled>Position</option>
                <option value="bottom-right">Bottom Right</option>
                <option value="bottom-left">Bottom Left</option>
                <option value="top-right">Top Right</option>
                <option value="top-left">Top Left</option>
              </NativeSelect>
              <FormHelperText>Position</FormHelperText>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                className={classes.selectEmpty}
                value={state.type}
                name="type"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'type' }}
              >
                <option value="" disabled>Type</option>
                <option value="none">None</option>
                <option value="info">Info</option>
                <option value="success">Success</option>
                <option value="warning">Warning</option>
                <option value="danger">Danger</option>
                <option value="primary">Primary</option>
              </NativeSelect>
              <FormHelperText>Type</FormHelperText>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.autoDismiss}
                  onChange={handleChecked}
                  name="autoDismiss"
                  color="primary"
                />
              }
              label="Auto Dismiss"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10} lg={8}>
            <Button fullWidth color="primary" onClick={() => addNotify()}>Show</Button>
            <MessageProvider messageList={messageList} position={state.position} onClosedItem={closeNotify} />
          </GridItem>
        </GridContainer>
        
      </CardBody>
    </Card>
  );
}
