import React, {FunctionComponent, useState, useEffect} from 'react';
import MapsToolbar from './MapsToolbar';
import L, { LatLngExpression } from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'assets/css/material-dashboard-react/views/leaflet.css';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Maps: FunctionComponent = () => {
  const [center, setCenter] = useState<LatLngExpression>([35.6997,51.3381]);
  const [zoom, setZoom] = useState(11);
  
  return(
    <div>
      <MapsToolbar />
      
      <MapContainer center={center} zoom={zoom} scrollWheelZoom={true}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
}

export default Maps;