import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-react/components/snackbarContentStyle.js";
import Box from '@material-ui/core/Box';

import 'assets/css/material-dashboard-react/components/MessageProvider.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: 15,
    minWidth: 400,
    maxWidth: 700,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  closeButtonRtl: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  typeIcon: {
    width: 50,
    height: 50,
  },
  title: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-all",
    overflow: "hidden",
    fontWeight: 'bold',
  },
  actionRTL: {
    direction: 'rtl'
  },
}));

const useSnackBarContentStyle = makeStyles(styles);

export default function MessageBox(props) {
  let { message, title, type, displayCloseButton, icon, buttons, rtlActive } = props;
  const classes = useStyles();
  const snackBarContentClasses = useSnackBarContentStyle();
  const [open, setOpen] = React.useState(true);

  if (props.autoDismiss === true) {
    setTimeout(props.onClose, props.autoDismissTimeOut);
  }

  const onClose = () => {
    props.onClose();
  }

  const handleClose = () => {
    setOpen(false);
  };

  const actionButton = (act) => {
    props.onClose();
    if(isFunction(act))
      act();
  }

  function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  let closeButton = [];
  if (displayCloseButton === true) {
    closeButton = [
      <IconButton
        className={rtlActive? classes.closeButtonRtl : classes.closeButton}
        color="inherit"
        onClick={onClose}
      >
        <Close className="close-Icon" />
      </IconButton>,
    ];
  }

  let rtlClass = props.rtlActive ? ' ' + classes.actionRTL : ''

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper + " " + snackBarContentClasses[type] + rtlClass}>
            {closeButton}
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              {icon !== undefined ?
                <Grid container xs={2} direction="column" justify="center" alignItems="center"> <props.icon className={classes.typeIcon} /></Grid>
                : null
              }
              <Grid item direction="row" xs={icon == undefined? 12:10}>
                {title !== undefined ? 
                <Grid item direction="row" xs={12}>
                  <h3 className={classes.title}>{title}</h3>
                </Grid> : null}
                <p className="transition-modal-message">{message}</p>

                {Array.isArray(buttons) &&
                  <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                    {buttons.map((btn, i) =>
                      <Button variant="contained" style={{ marginLeft: `10px` }} onClick={() => actionButton(btn.action)}>{btn.title}</Button>
                    )
                    }
                  </Grid>
                }
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

MessageBox.defaultProps = {
  displayCloseButton: true,
  type: 'info',
  rtlActive: false,
  autoDismiss: true,
  autoDismissTimeOut: 3000,
  displayMethod: 'notification',
}

MessageBox.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  displayCloseButton: PropTypes.bool,
  icon: PropTypes.object,
  rtlActive: PropTypes.bool,
  autoDismiss: PropTypes.bool,
  autoDismissTimeOut: PropTypes.number,
  onClose: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      action: PropTypes.func
    }),
  ),
  displayMethod: PropTypes.oneOf(["modal", "notification"]),
}