import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Notification from './Notification';
import MessageBox from './MessageBox';

import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import DangerIcon from "@material-ui/icons/Error";
import SuccessIcon from "@material-ui/icons/CheckCircle";

import 'assets/css/material-dashboard-react/components/MessageProvider.css';

const MessageProvider = props => {
    const { messageList, position } = props;
    const [list, setList] = useState(messageList);

    useEffect(() => {
        setList(messageList);
    }, [messageList, list]);

    const deleteMessage = (item) => {
        const index = list.findIndex(e => e === item);

        if(index < 0)
            return;

        list.splice(index, 1);
        setList([...list]);

        if(isFunction(props.onClosedItem))
            props.onClosedItem(item, index);
    }

    function isFunction(functionToCheck) {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }  

    const getIcon = (type) => {
        if(type == 'info' || type == undefined)
            return InfoIcon;
        else if (type == 'success')
            return SuccessIcon;
        else if (type == 'warning')
            return WarningIcon;
        else if (type == 'danger')
            return DangerIcon;

        return undefined;
    }

    return (
        <>
            <div className={`notification-container ${position}`}>
                {
                    list.map((message, i) => {
                        let icon = getIcon(message.type);

                        if(message.displayMethod == 'modal'){
                            return(
                                <MessageBox {...message} icon={icon} onClose={() => deleteMessage(message)}/>
                            )
                        }
                        else{
                            return(
                                <Notification index={i} {...message} position={position} icon={icon} 
                                    onClose={() => deleteMessage(message)}/>
                            )
                        }
                    })
                }
            </div>
        </>
    );
}

MessageProvider.propTypes = {
    messageList: PropTypes.array.isRequired,
    position: PropTypes.oneOf(["bottom-right", "bottom-left", "top-right", "top-left"]),
    onClosedItem: PropTypes.func,
}

MessageProvider.defaultProps = {
    position: 'bottom-right',
}

export default MessageProvider;